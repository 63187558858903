// Testing
// export const googleTag="G-686NEQBLHF"

//production & Staging
export const googleTag="G-8GMHM0PP92"


//local
// export const env="http://localhost:1337";


// testing
// export const env="https://64.227.158.67:1337";
// export const env="https://test.hrmnest.com/api"



// production
// export const env="https://hrmnest.com/api";



////staging 
// export const env="https://staging.hrmnest.com/api"
  export const env="https://us2-staging.hrmnest.com/api"